const axios = require('axios');
const baseUrl = '/api';
import Auth from '@/common/auth.js'

axios.interceptors.request.use(function (config) {
  // 在发送请求之前做些什么
  if(config.url=="/api/auth/login"){
    return config
  }
  config.headers.authorization = 'Bearer '+ Auth.getToken();
  return config;
}, function (error) {
  // 对请求错误做些什么
  return Promise.reject(error);
});

const get = async (url)=>{
    try {
      const response = await axios.get(baseUrl+url);
      console.log(response);
      return response.data
    } catch (error) {
      console.error(error);
      this.$toast('message',error)
    }
}
const post = async (url,data)=> {
    try {
      const response = await axios.post(baseUrl+url,data);
      console.log(response);
      return response.data
    } catch (error) {
      var res = JSON.parse(error.request.response);
      return res
      // this.$toast('message',error)
    }
}
export {
    get,
    post
}