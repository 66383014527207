import VueRouter from 'vue-router'
import Vue from 'vue'
Vue.use(VueRouter)
const ssHome = () => import('@/pages/Home');
const ssMe = () => import('@/pages/Me');
const ssLogin = () => import('@/pages/Login');
const ssview = () => import('@/pages/View');
const ssPersonal = () => import('@/pages/Form/Personal');
const sstuitionFees = () => import('@/pages/Form/TuitionFees');
const ssTrip = () => import('@/pages/Form/Trip');
const sschoice = () => import('@/pages/Loan/choice');
const sscolleges = () => import('@/pages/Loan/colleges');
const sssource = () => import('@/pages/Loan/source');
const ssSchoolVR = () => import('@/pages/SchoolVR');
const routes=[
    {
        path: '/',
        component: ssHome,
    },
    {
        path: '/home',
        component: ssHome,
    },
    {
        path: '/me',
        component: ssMe,
    },
    {
        path: '/login',
        component: ssLogin,
    },
    {
        path: '/view',
        component: ssview,
    },
    {
        path: '/personal',
        component: ssPersonal,
    },
    {
        path: '/tuitionFees',
        component: sstuitionFees,
    },
    {
        path: '/trip',
        component: ssTrip,
    },
    {
        path: '/choice',
        component: sschoice,
    },
    {
        path: '/colleges',
        component: sscolleges,
    },
    {
        path: '/source',
        component: sssource,
    },
    {
        path: '/schoolVR',
        component: ssSchoolVR,
    },
    
]

//防止路由报错
const originalPush = VueRouter.prototype.push;
VueRouter.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
}

const router = new VueRouter({
    routes
})
export default router