/**
 * 用户身份认证相关
 */
export default {

    /**
     * 不请求网络获取本地用户信息
     * @returns {boolean|*}
     */
    getLocalUserInfo() {
        let userInfo = JSON.parse(localStorage.getItem("studentInfo"));
        if(userInfo) {
            return userInfo;
        } else {
            return false;
        }
    },

    getToken(){
        let token = (localStorage.getItem("token"));
        if(token) {
            return token;
        } else {
            return false;
        } 
    },
        
    /**
     * 退出登录
     */
    loginOut() {
        localStorage.removeItem("studentInfo")
        localStorage.removeItem("token")
    },
}
