<template>
  <div id="app">
    <keep-alive>
      <router-view style="flex:1;"></router-view>
    </keep-alive>
  </div>
</template>

<script>
import Auth from '@/common/auth.js'
export default {
  name: 'App',
  data(){
    return {
      showFooter:false,
    }
  },
  watch: {
    $route: {
      handler: function (to, from) {
        console.log(to, from)
        // if(to.path=='/login' || to.path=='/view' || to.path=='/personal'){
        //   this.showFooter=false;
        // }else{
        //   this.showFooter=true;
        // }
      },
      deep: true,
      immediate: true,
    },
  },
  mounted(){
    //获得系统的全局配置信息
    this.setting();
    //没有登录跳转到登录页面
    var user = Auth.getLocalUserInfo();
    if(!user){
      return this.$router.push('/login')
    }
  },
  methods:{
        setting(){
            var that = this;
            this.$post('/auth/setting',{}).then(function(res){
                that.$store.commit('setglobalData',res.data)
            })
        },
    }
}
</script>

<style>
body,html{
  width: 100%;
  height: 100%;
}
body,html,div,ul,li{
  margin: 0;
  padding: 0;
}
#app {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}
</style>
