import Vue from 'vue'
import App from './App.vue'
import router from '@/router/index'
Vue.config.productionTip = false
import 'vant/lib/index.less';
import '@/assets/css/main.less';
import "@/assets/font/iconfont.css";
import '@/utils/vant.js';
import {post,get} from '@/utils/api.js';

import {setSessionItem,getSessionItem} from '@/utils/session.js';
Vue.prototype.$post = post;
Vue.prototype.$get = get;

Vue.prototype.$setSessionItem = setSessionItem;
Vue.prototype.$getSessionItem = getSessionItem;
import store from '@/store/index.js';
Vue.prototype.$store = store;
new Vue({
  render: h => h(App),
  router
}).$mount('#app')
