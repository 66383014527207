import Vue from 'vue'
import Vuex from 'vuex';
import {setSessionItem} from '@/utils/session.js';
Vue.use(Vuex);
// 创建一个新的 store 实例
const store = new Vuex.Store({
  state () {
    return {
      tabbarActive: 0,
      globalData:{},
      globaluserData:{},
    }
  },
  mutations: {
    changeTabbarActive (state,data) {
      state.tabbarActive=data;
      setSessionItem('tabbarActive',data)
    },
    setglobalData(state,data){
      state.globalData=data;
      setSessionItem('globalData',data)
    },
    setglobaluserData(state,data){
      state.globaluserData=data;
      setSessionItem('globaluserData',data)
    },
  },
//   modules: {
//     a: moduleA,
//     b: moduleB
//   }
})
export default store;