import Vue from 'vue'
import { Icon, Button, Tabbar, Calendar ,Radio,Picker ,TabbarItem, Step, Steps,Toast,CellGroup,Form,Field,Popup,Dialog,Uploader } from 'vant';
Vue.use(Tabbar)
.use(Icon)
.use(TabbarItem)
.use(Step)
.use(Steps)
.use(Toast)
.use(Form)
.use(Field)
.use(Picker)
.use(CellGroup)
.use(Popup)
.use(Radio)
.use(Calendar)
.use(Dialog)
.use(Uploader)
.use(Button);
// Toast
import 'vant/es/toast/style';
Vue.prototype.$toast = Toast;
// // Dialog
// import { Dialog } from 'vant';
// import 'vant/es/dialog/style';
// Vue.prototype.$dialog = Dialog;
// // Notify
// import { Notify } from 'vant';
// import 'vant/es/notify/style';

// // ImagePreview
// import { ImagePreview } from 'vant';
// import 'vant/es/image-preview/style';
